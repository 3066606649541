import axios from 'axios';
import customConfig from '../../custom_config';
import { getToken } from '../../assets/common_funcs/token_handle';

// axios发送request 请求 函数

const sendAxiosRequest = (api = '', method = 'get', reqParams = {}, reqObject = {}, timeout=20000) => {
  // 发送request请求
  let baseURL = customConfig.apiBaseUrl; // 开发环境跨域 api url

  // 创建axios实例
  const service = axios.create({
    baseURL: baseURL, // api的base_url
    timeout: timeout // 请求超时时间
  });

  // request拦截器
  service.interceptors.request.use(config => {
    config.headers["Content-Type"] = "application/json";
    if (getToken()) {
      // config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers["Authorization"] = getToken();
    }
    return config;
  },
    error => {
      // Do something with request error
      console.log(error); // for debug
      Promise.reject(error);
    }
  );

  // respone拦截器
  service.interceptors.response.use(response => {
    /**
     * code为非1000是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    // console.log(res);
    if (response.config.method === "delete") {
      // delete请求直接反回
      return Promise.resolve(res);
    }
    if (res.code !== 1000) {
      console.log('Server Error', res);

      // 401:未登录;
      if (res.code === 401 || res.code === 403) {
        alert('请登陆...');
      }
      return Promise.reject("error");
    } else {
      // return res;
      return Promise.resolve(res);
    }
  });

  let reqObj = {url: api, method: method};
  if (['get', 'delete'].includes(method)) {
    // get delete请求
    reqObj = {...reqObj, params: reqParams};
  } else {
    // post put请求
    reqObj = {...reqObj, data: reqObject};
  }
  // console.log(reqObj);
  return service(reqObj);
}

export { sendAxiosRequest };
