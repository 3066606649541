import React, { useState, useEffect } from 'react';
import { Spin, message } from 'antd';

import { sendAxiosRequest } from './sendRequest';

// axios request 请求组件
/**
 * 父组件使用方法
 * 引用
    import AxiosRequest from '../apis';
 * 定义:
    const [requestCount, setRequestCount] = useState(0);
    const reqParams = useRef({});
    const reqObject = useRef({ 'mobile': '', 'password': '' });
    const api = 'adminer/v1/admintoken';
    const method = 'post';

    const responseDataHandle = (respObj) => {
        // respObj 是请求结果
        console.log(respObj);
        ......
    }

* 组件写法:
    <AxiosRequest
        responseDataHandle={responseDataHandle} api={api} method={method}
        reqParams={reqParams.current}  reqObject={reqObject.current} requestCount={requestCount}
    ></AxiosRequest>

* 触发request请求 requestCount > 0才会促发 request
    setRequestCount(requestCount + 1);
 */

const AxiosRequest = ({ api, method, reqParams, reqObject, responseDataHandle, requestCount }) => {
    // requestCount值改变后 > 0 才会发送request请求, 避免初始化就请求数据出错

    const [loadingIcon, setLoadingIcon] = useState(false);  // 显示加载中

    const sendRequest = () => {
        // 发送request 请求 父组件通过 this.$Child.childMethod(); 调用
        setLoadingIcon(true);
        sendAxiosRequest(api, method, reqParams, reqObject)
            .then((response) => {
                // console.log('Success request:', response);
                responseDataHandle(response);
                setLoadingIcon(false);
            })
            .catch((err) => {
                console.log('Error request', err);
                setLoadingIcon(false);
                message.info(' ');
                message.error('Error: 连接服务器失败...');
                responseDataHandle({ code: 20001, msg: 'Request Error' });
            });
    }

    useEffect(() => {
        // sendRequest();
        if (requestCount > 0) {
            // requestCount > 0 才会发送request请求, 避免初始化就请求数据出错
            // console.log(requestCount);
            sendRequest();
        }
    }, [requestCount])

    return (
        <>
            {loadingIcon ? <Spin></Spin> : <div></div>}
        </>
    )

}

// props 默认值
AxiosRequest.defaultProps = {
    api: '',
    method: 'get',
    reqParams: {},
    reqObject: {},
    responseDataHandle: (respData) => { console.log(respData) },
    requestCount: 0
}

export default AxiosRequest;
