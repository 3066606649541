// token的保存和读取
import Cookies from 'js-cookie';
import customConfig from '../../custom_config';

// js-cookie install: npm install js-cookie --save

const TokenKey = customConfig.adminTokenKey;

export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, { expires: 7 });
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}